import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Document } from '@core/models';
import { Observable, firstValueFrom, map } from 'rxjs';
import yaml from 'js-yaml';

export enum Library {
  defaultDashboardTranslationEnGB = 'common/default-dashboard-en-gb-translation.yaml',
  defaultDashboardTranslationHiIN = 'common/default-dashboard-hi-in-translation.yaml',
  defaultDashboard = 'common/default-dashboard.yaml',
  defaultEmailTemplate = 'common/default-email-template.yaml',
  defaultGlobalTranslationEnGB = 'common/default-global-en-gb-translation.yaml',
  defaultGlobalTranslationEsES = 'common/default-global-es-es-translation.yaml',
  defaultGlobalTranslationHiIN = 'common/default-global-hi-in-translation.yaml',
  defaultRecordTypeTranslationEnGB = 'common/default-record-type-en-gb-translation.yaml',
  defaultRecordTypeTranslationhiIN = 'common/default-record-type-hi-in-translation.yaml',
  defaultRecordType = 'common/default-record-type.yaml',
  defaultReferenceDataTranslationEnGB = 'common/default-reference-data-en-gb-translation.yaml',
  defaultReferenceDataTranslationEsES = 'common/default-reference-data-es-es-translation.yaml',
  defaultReferenceDataTranslationHiIN = 'common/default-reference-data-hi-in-translation.yaml',
  defaultReferenceData = 'common/default-reference-data.yaml',
  defaultTermsOfUse = 'common/default-terms-of-use.yaml',
  defaultTranslation = 'common/default-translation.yaml',
  defaultUserData = 'common/default-user-data.yaml',
  defaultWorkspaceData = 'common/default-workspace-data.yaml',

  dashboardContentSchema = 'schemas/dashboard-content-schema.yaml',
  documentSchema = 'schemas/document-schema.yaml',
  emailTemplateContentSchema = 'schemas/email-template-content-schema.yaml',
  recordContentSchema = 'schemas/record-content-schema.yaml',
  recordTypeContentSchema = 'schemas/record-type-content-schema.yaml',
  referenceDataContentSchema = 'schemas/reference-data-content-schema.yaml',
  termsOfUseContentSchema = 'schemas/terms-of-use-content-schema.yaml',
  translationContentSchema = 'schemas/translation-content-schema.yaml'
}

@Injectable({
  providedIn: 'root'
})
export class LibraryService {
  private baseUri = '/assets/library/';
  private options = { responseType: 'text' as 'json' };

  constructor(private http: HttpClient) { }

  getItem$(item: Library): Observable<Document> {
    return this.http.get<string>(this.baseUri + item, this.options)
      .pipe(
        map((response) => (yaml.load(response) as Document)),
      );
  }

  async getItem(item: Library): Promise<Document> {
    return await firstValueFrom(
      this.http.get<string>(this.baseUri + item, this.options)
        .pipe(
          map((response) => (yaml.load(response) as Document)),
        ));
  }
}
